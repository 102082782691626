export enum Language {
  IT = 'it-IT',
  EN = 'en-US',
}

export const ALLOWED_LANGUAGES = Object.values(Language);

export const getLanguageLabel = (language: Language | undefined): string => {
  const languageMap = {
    [Language.IT]: 'Italiano',
    [Language.EN]: 'English',
  };

  if (!language) {
    return '';
  }

  return languageMap[language];
};
